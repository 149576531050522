import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import md5 from 'md5';
import {POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import {openMessageModal} from '@Modals/message-modal';
import {MESSAGE_TYPE} from '@Panels/message-panel';
import {authenticateUser, geCurrentUserData, getUserId, isUserLoggedIn, logOutUser} from '@Libraries/user.library';
import {openSaveConflictModal} from '@Modals/save-conflict-modal';
import {getEditPosterURL} from '@Libraries/poster-editor.library';
import type {PosterObjectBackend} from '@PosterWhiteboard/poster/poster-backend.types';
import {PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {User} from '@PosterWhiteboard/user/user.class';
import * as jsondiffpatch from 'jsondiffpatch';
import type {UserObject} from '@PosterWhiteboard/user/user.types';
import {logClientError} from '@Libraries/log.library';
import {isOnline, pollIsUserOnlineTillOnline, stopIsUserOnlinePoll} from '@Libraries/user-online-status.library';
import type {SavePosterError} from '@PosterWhiteboard/save-poster/save-poster.types';
import {areCookiesEnabled} from '@Utils/cookie.util';
import type {Unsubscribe} from 'redux';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {showMessageGrowl} from '@Components/message-growl';
import type {Delta} from 'jsondiffpatch/lib/types';
import {PAGE_WATERMARK_MODE} from '../page/page-watermark.class';

const PREVIEW_SCREEN_LARGER_DIMENSION = 690;
const SAVED_NOTIFICATION_TIMOUT_DURATION = 5000;
const AUTO_SAVE_INTERVAL = 7000;
interface SavePosterOpts {
  isInTeamSpace?: boolean;
  ignoreConflict?: boolean;
  sendPreview?: boolean;
  showLoadingToast?: boolean;
}

interface SaveResponse {
  folder: any;
  poster: SaveResponsePosterData;
}

interface SaveResponsePosterData {
  id?: string;
  hashedID?: string;
  lastModified?: number;
  idLastModifier?: string;
  seoName?: string;
}

interface SaveData {
  ignoreConflict: number;
  isInTeamSpace: number;
  posterData: string;
  checksum: string;
  posterId: string;
  posterVersion: number;
  isIncrementalSave: number;
  idFolder?: string;
  debugInfo?: object; // todo umar: temp for logging
}

export const SAVE_FAILED_DUE_TO_INCOMPLETE_IMAGE_UPLOAD_ERROR_SUBSTRING = 'no hashedFilename or fileExtension found for image with uid:';

export class SavePoster {
  public savedParentPosterBackendObject?: PosterObjectBackend;
  private lastSavedPosterObject?: PosterObjectBackend;
  private isFullPosterSaveRequired = false;
  private autoSaveInterval: undefined | NodeJS.Timeout;
  private readonly boundOnUnloadWindowSyncToPage;
  private readonly boundGeneratePreview;
  private unsubscribeOnPosterChange: Unsubscribe | undefined;
  private previousHasUnsavedChangesVal: boolean | undefined = undefined;
  private readonly poster: Poster;

  constructor(poster: Poster) {
    this.poster = poster;
    this.boundOnUnloadWindowSyncToPage = this.onUnloadWindow.bind(this);
    this.boundGeneratePreview = this.generatePreview.bind(this);
    window.addEventListener('beforeunload', this.boundOnUnloadWindowSyncToPage);
  }

  private onPosterChange(): void {
    const {hasUnsavedChanges} = window.PMW.redux.store.getState().posterEditor;
    if (hasUnsavedChanges !== this.previousHasUnsavedChangesVal) {
      this.previousHasUnsavedChangesVal = hasUnsavedChanges;
      if (hasUnsavedChanges) {
        this.triggerAutoSaveOnPosterChange();
      }
    }
  }

  private triggerAutoSaveOnPosterChange(): void {
    if (!this.canSave()) {
      return;
    }

    this.triggerAutoSave()
      .then(() => {
        this.triggerAutoSaveOnPosterChange();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  public isIncrementalSaveAvailable(): boolean {
    return window.PMW.currentUserOnPageLoad?.isSuper === true && !this.isFullPosterSaveRequired && this.lastSavedPosterObject !== undefined;
  }

  public isAutoSaveEnabled(): boolean {
    return (
      window.PMW.currentUserOnPageLoad?.isSuper === true &&
      !this.poster.isGalleryTemplate() &&
      !this.poster.isPosterInGeneration() &&
      !this.poster.mode.isWebpage() &&
      !this.poster.mode.isGeneration() &&
      isUserLoggedIn() &&
      !this.poster.isSuperUserEditingOthersPoster()
    );
  }

  public updateSavedPosterObject(posterBackendObject: PosterObjectBackend): void {
    this.lastSavedPosterObject = posterBackendObject;
  }

  public setIsFullPosterSaveRequired(val: boolean): void {
    this.isFullPosterSaveRequired = val;
  }

  private getDeltaForSave(posterBackendObject: PosterObjectBackend): Delta {
    return this.getJsonPatchInstanceForSavePoster().diff(this.lastSavedPosterObject, posterBackendObject);
  }

  public hasUnsavedChanges(): boolean {
    if (!this.lastSavedPosterObject) {
      return true;
    }

    try {
      return this.getJsonPatchInstanceForSavePoster().diff(this.lastSavedPosterObject, this.poster.getBackendObject()) !== undefined;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public onDispose(): void {
    this.disableTabClosePrompt();
    clearInterval(this.autoSaveInterval);
    window.removeEventListener('beforeunload', this.boundGeneratePreview);
    if (this.unsubscribeOnPosterChange) {
      this.unsubscribeOnPosterChange();
    }
    stopIsUserOnlinePoll();
  }

  private disableTabClosePrompt(): void {
    window.removeEventListener('beforeunload', this.boundOnUnloadWindowSyncToPage);
  }

  public async save(opts: SavePosterOpts = {}, showLoadingToast = true, onCloseAuthenticateModal?: () => void): Promise<void> {
    if (this.isAutoSaveEnabled()) {
      try {
        await this.triggerAutoSave();
      } catch (e) {
        console.error(e);
      }
      return;
    }

    await this.triggerManualSave(opts, showLoadingToast, onCloseAuthenticateModal);
  }

  public async triggerManualSave(
    {isInTeamSpace = false, ignoreConflict = false, sendPreview = true}: SavePosterOpts = {},
    showLoadingToast = true,
    onCloseAuthenticateModal?: () => void
  ): Promise<void> {
    if (!this.canSave()) {
      return;
    }

    const trace = new Error().stack;
    await authenticateUser(async (): Promise<void> => {
      try {
        if (showLoadingToast) {
          showLoading('savingPoster', {
            text: window.i18next.t('pmwjs_saving'),
          });
        }

        await this.doSavePoster({
          isInTeamSpace,
          ignoreConflict,
          sendPreview,
          showLoadingToast,
        });
      } catch (e) {
        if (this.isSaveErrorDueToIncompleteImageUploads(e)) {
          void logClientError(e, {
            saveTrace: trace,
            posterReduxData: {...window.PMW.redux.store.getState().posterEditor},
          });
        }
        void this.onManualSaveError(e as SavePosterError | undefined);
      } finally {
        if (showLoadingToast) {
          hideLoading('savingPoster');
        }
      }
    }, onCloseAuthenticateModal);
  }

  public isPosterSaving(): boolean {
    return window.PMW.redux.store.getState().posterEditor.isPosterSaving;
  }

  public didPosterSavePreviouslyFail(): boolean {
    return window.PMW.redux.store.getState().posterEditor.didPosterSaveFailed;
  }

  private canSave(): boolean {
    return this.hasUnsavedChanges() && !this.poster.areAnyImagesUploading() && !this.isPosterSaving();
  }

  private async triggerAutoSave(): Promise<void> {
    if (!this.canSave()) {
      return;
    }

    try {
      await this.doSavePoster({
        showLoadingToast: false,
        sendPreview: false,
      });
      this.poster.redux.updateDidPosterSaveFailed(false);
    } catch (e) {
      void this.handleSaveErrorForAutoSave(e);
      throw e;
    }
  }

  private async doSavePoster({isInTeamSpace = false, ignoreConflict = false, sendPreview = true, showLoadingToast = true}: SavePosterOpts = {}): Promise<void> {
    this.poster.redux.updateIsPosterSaving(true);
    this.poster.deleteItemsNotVisibleOnPoster(false);
    // We're guaranteed a logged-in user. Use their info.
    this.poster.idLastModifier = getUserId()?.toString() as string;
    // todo umar: temp for logging
    let creatorInfoFlow: string = 'normal';
    const originalUserIdInCookie = window.PMW.getUserId();
    let userDataFromServer: Partial<UserObject> | undefined;

    if (this.poster.creator === undefined) {
      creatorInfoFlow = 'undefined';
      const userData = (await geCurrentUserData()) as Partial<UserObject>;
      this.poster.creator = new User(userData);
    } else if (this.poster.creator.id === '-1') {
      creatorInfoFlow = 'defaultId';
      userDataFromServer = (await geCurrentUserData()) as Partial<UserObject> | undefined;
      this.poster.creator?.copyVals((await geCurrentUserData()) as Partial<UserObject>);
    }
    this.poster.setMenuItemsWrappingInfo();
    this.poster.validatePagesIntroAnimation();
    const posterObjectToSave = this.poster.getBackendObject();
    const isIncrementalSave = this.isIncrementalSaveAvailable();
    const posterDataJson = JSON.stringify({
      poster: isIncrementalSave ? this.getDeltaForSave(posterObjectToSave) : posterObjectToSave,
    });

    const data: SaveData = {
      ignoreConflict: ignoreConflict ? 1 : 0,
      isInTeamSpace: isInTeamSpace ? 1 : 0,
      posterData: posterDataJson,
      posterId: this.poster.id,
      isIncrementalSave: isIncrementalSave ? 1 : 0,
      checksum: md5(posterDataJson),
      posterVersion: POSTER_VERSION.CURRENT,
    };

    if (this.poster.folder) {
      data.idFolder = this.poster.folder.id;
    }

    if (this.poster.creator?.id === '-1') {
      // todo umar: temp for logging
      data.debugInfo = {
        creatorInfoFlow,
        isEmbedded: window.PMW.isEmbedded() ? 1 : 0,
        userDataFromServer: {
          id: userDataFromServer?.id,
          isNull: userDataFromServer === undefined ? 1 : 0,
        },
        cookieUserId: window.PMW.getUserId(),
        originalUserIdInCookie,
        areCookiesEnabled: areCookiesEnabled(),
      };
    }

    try {
      const response = (await window.PMW.writeLocal('posterbuilder/savePoster', data)) as SaveResponse;
      await this.poster.updateFromObject(response.poster, {updateRedux: false, undoable: false});
      this.lastSavedPosterObject = {
        ...posterObjectToSave,
        ...response.poster,
      };
      this.isFullPosterSaveRequired = false;
      this.poster.redux.updateReduxData();
      this.updateUrl();
      this.poster.updateTitle();
      if (sendPreview) {
        this.savePosterPreview().catch((e) => {
          console.error(e);
        });
      }
      this.onPosterSaved(showLoadingToast);
      this.updateDidPosterRecentlySave();

      if (window.PMW.isEmbedded()) {
        window.parent.postMessage(`Save~${this.poster.hashedID}`, '*');
      }
    } finally {
      this.poster.redux.updateIsPosterSaving(false);
    }
  }

  private updateDidPosterRecentlySave(): void {
    this.poster.redux.updateDidPosterRecentlySave(true);
    setTimeout((): void => {
      this.poster.redux.updateDidPosterRecentlySave(false);
    }, 1500);
  }

  private async handleSaveErrorForAutoSave(e: unknown): Promise<void> {
    if (!this.didPosterSavePreviouslyFail() && (await this.showSpecificPosterSaveError(e as SavePosterError | undefined))) {
      this.poster.redux.updateDidPosterSaveFailed(true);
      return;
    }

    // We change the autosave icon to offline in this case so no need to show an error
    if (!isOnline()) {
      pollIsUserOnlineTillOnline();
      return;
    }

    if (!this.didPosterSavePreviouslyFail()) {
      this.poster.redux.updateDidPosterSaveFailed(true);
      openMessageModal({
        type: MESSAGE_TYPE.DANGER,
        text: window.i18next.t('pmwjs_save_poster_error'),
      });
    }
  }

  private async showSpecificPosterSaveError(error?: SavePosterError): Promise<boolean> {
    if (!error) {
      return false;
    }

    switch (error.code) {
      case 'cannot-edit-poster': {
        if (error.data.isUserContributor) {
          openMessageModal({
            title: window.i18next.t('pmwjs_permission_denied'),
            text: window.i18next.t('pmwjs_edit_permission_denied'),
          });
        }

        openMessageModal({
          title: window.i18next.t('pmwjs_design_belongs_to_another_user_title'),
          text: window.i18next.t('pmwjs_design_belongs_to_another_user'),
        });
        return true;
      }
      case 'cannot-copy-poster':
        openMessageModal({
          title: window.i18next.t('pmwjs_permission_denied'),
          text: window.i18next.t('pmwjs_copy_permission_denied'),
        });
        return true;

      case 'not-logged-in':
        await this.handleLoggedOutUser(window.i18next.t('pmwjs_logged_out_title'), window.i18next.t('pmwjs_logged_out_message'));
        return true;

      case 'save-conflict': {
        if (error.data.conflictUser) {
          this.onSaveConflict(error.data.conflictUser.name);
          return true;
        }
        break;
      }

      case 'rejected-template': {
        openMessageModal({
          title: error.data.rejectionTitle,
          text: error.data.rejectionText,
        });
        return true;
      }

      default:
        break;
    }
    return false;
  }

  private onPosterSaved(doShowMessageGrowl = true): void {
    if (this.poster.mode.details.type === PosterModeType.REGEN) {
      window.location.href = window.PMW.util.site_url(`cart/requestDownloadRegen/${this.poster.mode.details.orderId}`);
      return;
    }
    if (doShowMessageGrowl) {
      showMessageGrowl({
        text: window.i18next.t('pmwjs_saved_successfully'),
        key: 'savedPoster',
        interval: SAVED_NOTIFICATION_TIMOUT_DURATION,
      });
    }
    void geCurrentUserData().then((userData: Partial<UserObject> | undefined) => {
      if (userData?.verificationNeededStatus) {
        window.location.href = window.PMW.util.site_url('authenticate/verificationneeded?saved=1');
      } else {
        window.PMW.premiumDialogs.openTrialDialog(true, 'save');
      }
    });

    this.poster.resizePoster.copyVals(this.poster.toObject());

    if (window.PMW.isEmbedded() && !this.isAutoSaveEnabled()) {
      openMessageModal({
        width: '400px',
        height: '270px',
        title: window.i18next.t('pmwjs_poster_saved'),
        text: window.i18next.t('pmwjs_close_editor_safely'),
        showIcon: false,
        ctaButton: {
          text: window.i18next.t('pmwjs_close_editor'),
          onClick: (): void => {
            window.parent.postMessage('Close~iframe', '*');
          },
        },
      });
    }

    trackPosterBuilderGA4Events(GA4EventName.SAVED_POSTER);
  }

  private onUnloadWindow(e: BeforeUnloadEvent): string | undefined {
    if (this.alertBeforeClosing()) {
      e.preventDefault();
      e.returnValue = null;
      return window.i18next.t('pmwjs_onUnloadWindow');
    }
    return undefined;
  }

  public alertBeforeClosing(): boolean {
    if (window.PMW.isStorybook() || this.poster.mode.isGeneration()) {
      return false;
    }

    if (this.poster.mode.isCopy() && !this.poster.id) {
      return this.didUserChangeTemplate();
    }

    return this.hasUnsavedChanges();
  }

  public consoleUnsavedChanges(): void {
    console.log(this.getJsonPatchInstanceForSavePoster().diff(this.lastSavedPosterObject, this.poster.getBackendObject()));
  }

  public consoleChangeTemplate(): void {
    try {
      const jsonPatchInstance = jsondiffpatch.create({
        propertyFilter: (name: string, context: jsondiffpatch.DiffContext) => {
          if (context.childName === undefined) {
            return (
              name === 'height' ||
              name === 'pages' ||
              name === 'width' ||
              name === 'audioClips' ||
              name === 'type' ||
              name === 'units' ||
              name === 'userHeight' ||
              name === 'userWidth'
            );
          }

          return true;
        },
      });
      console.log(jsonPatchInstance.diff(this.savedParentPosterBackendObject, this.poster.getBackendObject()));
    } catch (e) {
      console.error(e);
    }
  }

  private didUserChangeTemplate(): boolean {
    try {
      const jsonPatchInstance = jsondiffpatch.create({
        propertyFilter: (name: string, context: jsondiffpatch.DiffContext) => {
          if (context.childName === undefined) {
            return (
              name === 'height' ||
              name === 'pages' ||
              name === 'width' ||
              name === 'audioClips' ||
              name === 'type' ||
              name === 'units' ||
              name === 'userHeight' ||
              name === 'userWidth'
            );
          }

          return !(
            typeof context.left === 'object' &&
            context.left &&
            'gitype' in context.left &&
            context.left.gitype === 'video' &&
            (name === 'frameRate' || name === 'duration')
          );
        },
      });
      return jsonPatchInstance.diff(this.savedParentPosterBackendObject, this.poster.getBackendObject()) !== undefined;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private async savePosterPreview(): Promise<void> {
    const img = this.poster.getCurrentPage().pageSnapshot.getSnapshot({
      scale: PREVIEW_SCREEN_LARGER_DIMENSION / (this.poster.width > this.poster.height ? this.poster.width : this.poster.height),
      enableTransparency: false,
      mode: PAGE_WATERMARK_MODE.NONE,
    });
    await window.PMW.write(window.PMW.util.site_url('posterbuilder/savePreview'), {
      id: this.poster.hashedID,
      checksum: md5(img),
      img,
    });
  }

  private async onManualSaveError(saveErrorData?: SavePosterError): Promise<void> {
    if (!(await this.showSpecificPosterSaveError(saveErrorData))) {
      console.error(saveErrorData);
      openMessageModal({
        type: MESSAGE_TYPE.DANGER,
        text: window.i18next.t('pmwjs_save_poster_error'),
      });
    }
  }

  /**
   * After a poster is saved, makes sure the URL is updated to include the poster's hashed ID and uses the /load
   * server-side method used for editing the poster. This allows the user to copy the URL to edit their poster in the
   * future.
   */
  private updateUrl(): void {
    if (!window.PMW.isStorybook() && window.history && window.location && window.location.pathname && window.location.pathname.length > 0) {
      // The regex expression removes any leading slash in the page path
      const path = window.PMW.util.getCurrentPagePath().replace(/^\/(.*)/, '$1');
      const pathSegments: Array<string> = path.split('/');
      const separator = window.PMW.util.getPagePathSeparatorToken();
      const domain = window.location.pathname.split(separator)[0];
      let c = '';
      let m = '';
      const newPath = [];

      newPath.push(domain + separator);

      if (pathSegments !== undefined && pathSegments[0]) {
        [c] = pathSegments;
      }
      if (pathSegments !== undefined && pathSegments[1]) {
        [, m] = pathSegments;
      }

      // don't update the URL if it was already the load URL
      if (c.length > 0 && m !== 'load') {
        newPath.push(c);
        newPath.push('load');
        newPath.push(this.poster.hashedID);

        let loadUrl = newPath.join('/');

        if (window.PMW.isEmbedded()) {
          const embeddedParams = window.PMW.util.getEmbeddedParamsFromUrlAsArray();
          if (embeddedParams) {
            loadUrl += '?';
            embeddedParams.forEach(([key, value]) => {
              loadUrl = `${loadUrl + key}=${value}&`;
            });
            loadUrl = loadUrl.substring(0, loadUrl.length - 1);
          }
        }

        window.history.replaceState('saved', 'saved', loadUrl);
        this.poster.history.reset();
      }
    }
  }

  /**
   * Displays a message informing the user that they are not logged in and must log in before they can save.
   * When this dialog is closed, the authentication dialog is opened.
   */
  private async handleLoggedOutUser(title: string, text: string): Promise<void> {
    try {
      await logOutUser();
    } catch (e) {
      console.error('error in logging user out. Details: ');
      console.error(e);
    }
    return new Promise((resolve) => {
      openMessageModal({
        title,
        text,
        onClose: authenticateUser.bind(null, async () => {
          await this.save();
          resolve();
        }),
      });
    });
  }

  private getJsonPatchInstanceForSavePoster(): jsondiffpatch.DiffPatcher {
    return jsondiffpatch.create({
      propertyFilter: (name: string, context: jsondiffpatch.DiffContext) => {
        return !(typeof context.left === 'object' && context.left && 'gitype' in context.left && context.left.gitype === 'video' && (name === 'frameRate' || name === 'duration'));
      },
    });
  }

  private onSaveConflict(userName: string): void {
    openSaveConflictModal({
      conflictWith: userName,
      saveTheirsCallback: () => {
        this.disableTabClosePrompt();
        window.location.href = getEditPosterURL(this.poster.hashedID);
      },
      saveYoursCallback: () => {
        void this.save({
          ignoreConflict: true,
        });
      },
    });
  }

  private isSaveErrorDueToIncompleteImageUploads(e: unknown): boolean {
    return !!(typeof e === 'object' && e && 'message' in e && typeof e.message === 'string' && e.message.includes(SAVE_FAILED_DUE_TO_INCOMPLETE_IMAGE_UPLOAD_ERROR_SUBSTRING));
  }

  private generatePreview(): void {
    void window.PMW.writeLocal('posterbuilder/generatePreview', {
      posterHashId: this.poster.hashedID,
    }).catch((e) => {
      console.error(e);
    });
  }

  public initAutoSave(): void {
    if (!this.isAutoSaveEnabled()) {
      return;
    }

    this.unsubscribeOnPosterChange = window.PMW.redux.store.subscribe(this.onPosterChange.bind(this));
    window.addEventListener('beforeunload', this.boundGeneratePreview);

    this.autoSaveInterval = setInterval(() => {
      this.triggerAutoSave().catch((e) => {
        console.error(e);
      });
    }, AUTO_SAVE_INTERVAL);
  }
}
