import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {PosterTypeBackendObject} from '@PosterWhiteboard/poster/poster-backend.types';
import type {PosterTypeObject} from '@PosterWhiteboard/poster-type/poster-type.types';

export interface PosterModeObject {
  details: PosterModeDetails;
}

export enum PosterModeType {
  EDIT = 'edit',
  COPY = 'copy',
  RESIZE_TO = 'resizeTo',
  CREATE = 'create',
  REGEN = 'regen',
  WEB_PAGE = 'webpage',
  GENERATE = 'generate',
}

export type PosterModeDetails = PosterModeEdit | PosterModeGenerate | PosterModeWebpage | PosterModeCopy | PosterModeRegen | PosterModeCreate | PosterModeResize;

interface PosterModeEdit {
  type: PosterModeType.EDIT;
  posterHashId: string;
  posterWidth: number;
  posterHeight: number;
  posterVersionId?: string;
}

interface PosterModeGenerate {
  type: PosterModeType.GENERATE;
  posterHashId: string;
  posterWidth: number;
  posterHeight: number;
  showLargeWatermarkOnVideoGeneration: boolean;
  optimizeItemLoad: boolean;
  posterVersionId?: string;
}

interface PosterModeWebpage {
  type: PosterModeType.WEB_PAGE;
  posterHashId: string;
  posterWidth: number;
  posterHeight: number;
  posterVersionId?: string;
}

interface PosterModeCopy {
  type: PosterModeType.COPY;
  posterHashId: string;
  posterWidth: number;
  posterHeight: number;
  posterVersionId?: string;
}

interface PosterModeRegen {
  type: PosterModeType.REGEN;
  orderId: string;
  posterHashId: string;
  posterWidth: number;
  posterHeight: number;
  posterVersionId?: string;
}

export interface PosterModeResize {
  type: PosterModeType.RESIZE_TO;
  posterHashId: string;
  units: string;
  createCopy: boolean;
  posterWidth: number;
  posterHeight: number;
  userWidth: number;
  userHeight: number;
  posterTypeName: string;
  posterType: PosterTypeObject;
  isCustomDimension: boolean;
  posterVersionId?: string;
}

export interface PosterModeCreate {
  type: PosterModeType.CREATE;
  posterType: PosterTypeBackendObject;
  posterWidth: number;
  posterHeight: number;
  userWidth: number;
  userHeight: number;
  units: string;
}

export class PosterMode extends CommonMethods {
  public details: PosterModeDetails;

  constructor(details: PosterModeDetails) {
    super();
    this.details = details;
  }

  public isNewPoster(): boolean {
    return this.isCopyOfAPoster() || this.details.type === PosterModeType.CREATE;
  }

  public isCopyOfAPoster(): boolean {
    return this.details.type === PosterModeType.COPY || (this.details.type === PosterModeType.RESIZE_TO && this.details.createCopy);
  }

  public isRegen(): boolean {
    return this.details.type === PosterModeType.REGEN;
  }

  public isCopy(): boolean {
    return this.details.type === PosterModeType.COPY;
  }

  public isWebpage(): boolean {
    return this.details.type === PosterModeType.WEB_PAGE;
  }

  public isGeneration(): boolean {
    return this.details.type === PosterModeType.GENERATE;
  }

  public shouldOptimizeItemLoadForGeneration(): boolean {
    return this.details.type === PosterModeType.GENERATE && this.details.optimizeItemLoad;
  }

  public toObject(): PosterModeObject {
    return {
      details: this.details,
    };
  }
}
