import type {ItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {
  BackgroundBackendObject,
  BaseItemBackendObject,
  EffectsBackend,
  FancyTextItemBackendObject,
  GradientBackgroundBackendObject,
  ImageBackgroundItemBackendObject,
  ImageItemBackendObject,
  ImageSlideItemBackendObject,
  ItemBackendObject,
  MaskingFreehandBackend,
  MaskingItemBackend,
  MaskingItemsBackend,
  MaskingShapeBackend,
  MaskingTextBackend,
  MenuItemBackendObject,
  PageBackendObject,
  TranscriptItemBackendObject,
  QRCodeItemBackendObject,
  RemoveVideoBackgroundBackend,
  SlideBackendObject,
  SlideshowItemBackendObject,
  SolidBackgroundBackendObject,
  StickerItemBackendObject,
  TableItemBackendObject,
  TabsItemBackendObject,
  TextItemBackendObject,
  TextListBackend,
  TextSlideItemBackendObject,
  TextStylesBackend,
  TransparentBackgroundBackendObject,
  VectorItemBackendObject,
  VideoItemBackendObject,
  VideoSlideItemBackendObject,
  RectangleItemBackendObject,
  PosterObjectBackend,
  AudioClipsBackendObject,
  AudioItemBackendObject,
} from '@PosterWhiteboard/poster/poster-backend.types';
import {BackgroundTypeBackend, GlowType, GradientBackgroundBackendType, ShadowType, ShapeType} from '@PosterWhiteboard/poster/poster-backend.types';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {ColorBackgroundObject} from '@PosterWhiteboard/page/background/color-background.class';
import type {RGB} from '@Utils/color.util';
import {rgbToHex} from '@Utils/color.util';
import type {QRCodeItemObject} from '@PosterWhiteboard/items/qr-code-item.class';
import type {FancyTextItemObject} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {StickerItemObject} from '@PosterWhiteboard/items/sticker-item.class';
import type {TextSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import type {TableItemObject} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {MenuItemObject} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {TabsItemObject} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import type {SlideshowItemObject} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {TransitionObject} from '@PosterWhiteboard/models/transition.class';
import type {ImageSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {ImageBackgroundObject} from '@PosterWhiteboard/page/background/image-background.class';
import type {ImageBackgroundItemObject} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {MaskingItemObject} from '@PosterWhiteboard/classes/item-masking.class';
import {MaskingType} from '@PosterWhiteboard/classes/masking/masking.class';
import type {MaskingText} from '@PosterWhiteboard/classes/masking/masking-text.class';
import type {MaskingShape} from '@PosterWhiteboard/classes/masking/masking-shape.class';
import type {MaskingFreehand} from '@PosterWhiteboard/classes/masking/masking-freehand.class';
import {AuraType} from '@PosterWhiteboard/classes/item-aura.class';
import type {RectangleItemObject} from '@PosterWhiteboard/items/rectangle-item/rectangle-item';
import type {PosterLoadObject, PosterPageObject} from '@PosterWhiteboard/poster/poster.types';
import type {PageBackgroundObject} from '@PosterWhiteboard/page/page-background.class';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {PageObject} from '@PosterWhiteboard/page/page.types';
import type {AudioClipsObject} from '@PosterWhiteboard/classes/audio-clips/audio-clips.class';

export const getPosterBackendObjectFromPoster = (poster: PosterLoadObject): PosterObjectBackend => {
  if (!poster.creator) {
    throw new Error(`Poster with no creator can't be saved`);
  }

  return {
    height: poster.height,
    pages: getBackendPosterPagesObjectFromPoster(poster.pages),
    width: poster.width,
    createdOn: poster.createdOn,
    creator: poster.creator
      ? {
          id: poster.creator.id,
          fbId: poster.creator.fbId,
          name: poster.creator.name,
          type: poster.creator.type,
          watermark: poster.creator.watermark ?? '-1',
          premiumLevel: poster.creator.premiumLevel,
          preferredLanguage: poster.creator.preferredLanguage,
          verificationNeededStatus: poster.creator.verificationNeededStatus,
        }
      : null,
    description: poster.description,
    hashedID: poster.hashedID,
    id: poster.id === '' ? '-1' : poster.id,
    audioClips: getBackendPosterAudioClipsFromPoster(poster.audioClips),
    idGalleryTemplate: poster.idGalleryTemplate ? poster.idGalleryTemplate : null,
    idGalleryTemplateCreator: poster.idGalleryTemplateCreator,
    idLastModifier: poster.idLastModifier,
    idTemplate: poster.idTemplate === '' ? null : poster.idTemplate,
    isCopyable: poster.isCopyable,
    isInternal: poster.isInternal,
    isPublic: poster.isPublic,
    isPurchaseable: poster.isPurchaseable,
    isTemplate: poster.isTemplate,
    lastModified: poster.lastModified,
    name: poster.name,
    seoName: poster.seoName,
    type: poster.type,
    units: poster.units,
    userHeight: poster.userHeight,
    userWidth: poster.userWidth,
    version: poster.version,
  };
};

export const getBackendPosterPagesObjectFromPoster = (pages: PosterPageObject): PageBackendObject[] => {
  const pagesBackend: PageBackendObject[] = [];

  for (const pageHashedID of pages.pageOrder) {
    const page = pages.pagesHashMap[pageHashedID];

    pagesBackend.push({
      introAnimation: {
        type: page.introAnimation.animation.type,
        slideType: page.introAnimation.animation.slideType,
        speed: page.introAnimation.animation.speed,
      },
      duration: page.duration,
      hashedID: page.hashedID,
      takeDurationFrom: 'video',
      background: getBackendPageBackgroundObjectForPage(page.background),
      graphicItems: getBackendItemsObjectForPage(page),
    });
  }

  return pagesBackend;
};

export const getBackendPosterAudioClipsFromPoster = (audioClips: AudioClipsObject): AudioClipsBackendObject => {
  const audioBackendItemsHashMap: Record<string, AudioItemBackendObject> = {};

  for (const [key, audioItemObject] of Object.entries(audioClips.audioItemsHashMap)) {
    audioBackendItemsHashMap[key] = {
      audioPlayer: {
        audioUrl: audioItemObject.audioPlayer.audioUrl,
        trim: audioItemObject.audioPlayer.trim,
        fade: audioItemObject.audioPlayer.fade,
        originalDuration: audioItemObject.audioPlayer.originalDuration,
        playCycles: audioItemObject.audioPlayer.playCycles,
        volume: audioItemObject.audioPlayer.volume,
        speed: audioItemObject.audioPlayer.speed,
      },
      hashedFilename: audioItemObject.hashedFilename,
      name: audioItemObject.name,
      onPosterStartTime: audioItemObject.onPosterStartTime,
      source: audioItemObject.source,
      uid: audioItemObject.uid,
    };
  }
  return {
    audioItemsHashMap: audioBackendItemsHashMap,
  };
};

const getBackendPageBackgroundObjectForPage = (pageBackground: PageBackgroundObject): BackgroundBackendObject => {
  switch (pageBackground.details.type) {
    case BackgroundTypeName.COLOR:
      return getBackendPageColorBackgroundObjectForPage(pageBackground.details);

    case BackgroundTypeName.IMAGE:
      return getBackendPageImageBackgroundObjectForPage(pageBackground.details);

    default:
      throw new Error(`unhandled page background type`);
  }
};

const getBackendPageColorBackgroundObjectForPage = (
  colorBackground: ColorBackgroundObject
): SolidBackgroundBackendObject | GradientBackgroundBackendObject | TransparentBackgroundBackendObject => {
  switch (colorBackground.fill.fillType) {
    case FillTypes.SOLID:
      return {
        pmvcName: BackgroundTypeBackend.SOLID,
        primaryColor: rgbToHex(colorBackground.fill.fillColor[0]),
      } as SolidBackgroundBackendObject;

    case FillTypes.RADIAL_GRADIENT:
      return {
        pmvcName: BackgroundTypeBackend.GRADIENT,
        primaryColor: rgbToHex(colorBackground.fill.fillColor[0]),
        secondaryColor: rgbToHex(colorBackground.fill.fillColor[1]),
        gradientType: GradientBackgroundBackendType.RADIAL,
      } as GradientBackgroundBackendObject;

    case FillTypes.LINEAR_GRADIENT:
      return {
        pmvcName: BackgroundTypeBackend.GRADIENT,
        primaryColor: rgbToHex(colorBackground.fill.fillColor[0]),
        secondaryColor: rgbToHex(colorBackground.fill.fillColor[1]),
        gradientType: GradientBackgroundBackendType.LINEAR,
      } as GradientBackgroundBackendObject;

    case FillTypes.NONE:
      return {
        pmvcName: BackgroundTypeBackend.TRANSPARENT,
      } as TransparentBackgroundBackendObject;

    default:
      throw new Error(`unhandled colorBackground type: ${colorBackground.fill.fillType}`);
  }
};

const getBackendPageImageBackgroundObjectForPage = (imageBackground: ImageBackgroundObject): ImageBackgroundItemBackendObject => {
  return {
    ...getBackendImageItemObjectForItem(imageBackground.imageBackgroundItemObject),
    pmvcName: BackgroundTypeBackend.IMAGE,
    gitype: ITEM_TYPE.IMAGEBACKGROUND,
    transparency: imageBackground.imageBackgroundItemObject.transparency,
    attributionName: imageBackground.imageBackgroundItemObject.attributionName,
    attributionURL: imageBackground.imageBackgroundItemObject.attributionURL,
    licenseName: imageBackground.imageBackgroundItemObject.licenseName,
    licenseURL: imageBackground.imageBackgroundItemObject.licenseURL,
    displayAttribution: imageBackground.imageBackgroundItemObject.displayAttribution,
    idUser: imageBackground.imageBackgroundItemObject.idUser,
  };
};

const getBackendItemsObjectForPage = (page: PageObject): ItemBackendObject[] => {
  const backendItemsObject = [];

  for (const hashedItemID of page.items.itemsOrder) {
    backendItemsObject.push(getBackendItemObjectForItem(page.items.itemsHashMap[hashedItemID]));
  }

  return backendItemsObject;
};

const getBackendItemObjectForItem = (item: ItemObject): ItemBackendObject => {
  switch (item.gitype) {
    case ITEM_TYPE.IMAGE:
      return getBackendImageItemObjectForItem(item as ImageItemObject);

    case ITEM_TYPE.IMAGESLIDE:
      return getBackendImageSlideItemObjectForItem(item as ImageSlideItemObject);

    case ITEM_TYPE.QR_CODE:
      return getBackendQRItemObjectForItem(item as QRCodeItemObject);

    case ITEM_TYPE.FANCY_TEXT:
      return getBackendFancyTextItemObjectForItem(item as FancyTextItemObject);

    case ITEM_TYPE.VECTOR:
      return getBackendVectorItemObjectForItem(item as VectorItemObject);

    case ITEM_TYPE.VIDEO:
      return getBackendVideoItemObjectForItem(item as VideoItemObject);

    case ITEM_TYPE.VIDEOSLIDE:
      return getBackendVideoSlideItemObjectForItem(item as VideoSlideItemObject);

    case ITEM_TYPE.STICKER:
      return getBackendStickerItemObjectForItem(item as StickerItemObject);

    case ITEM_TYPE.TEXT:
      return getBackendTextItemObjectForItem(item as TextItemObject);

    case ITEM_TYPE.TEXTSLIDE:
      return getBackendTextSlideItemObjectForItem(item as TextSlideItemObject);

    case ITEM_TYPE.TABLE:
      return getBackendTableItemObjectForItem(item as TableItemObject);

    case ITEM_TYPE.MENU:
      return getBackendMenuItemObjectForItem(item as MenuItemObject);

    case ITEM_TYPE.TAB:
      return getBackendTabsItemObjectForItem(item as TabsItemObject);

    case ITEM_TYPE.SLIDESHOW:
      return getBackendSlideshowItemObjectForItem(item as SlideshowItemObject);

    case ITEM_TYPE.TRANSCRIPT:
      return getBackendTranscriptItemObjectForItem(item as TranscriptItemObject);

    case ITEM_TYPE.RECTANGLE:
      return getBackendRectangleItemObjectForItem(item as RectangleItemObject);

    default:
      throw new Error(`unhandled graphic item: ${item.gitype}`);
  }
};

const getBackendImageItemObjectForItem = (item: ImageItemObject | ImageSlideItemObject | ImageBackgroundItemObject): ImageItemBackendObject => {
  if (!item.hashedFilename || !item.fileExtension) {
    throw new Error(
      `no hashedFilename or fileExtension found for image with uid: ${item.uid}. hashedFilename: ${item.hashedFilename}, fileExtension: ${item.fileExtension}, trace: ${new Error().stack}`
    );
  }
  return {
    ...getBaseBackendItemObjectFromItem(item),
    ...getBackendEffectObjectFromItem(item),
    gitype: ITEM_TYPE.IMAGE,
    hashedFilename: item.hashedFilename,
    imageUid: '',
    fileExtension: item.fileExtension,
    loadedImageHeight: item.loadedImageHeight,
    loadedImageWidth: item.loadedImageWidth,
    hasTransparency: item.hasTransparency === true,
    isRemoved: item.isRemoved,
    isPurchased: item.isPurchased,
    type: 0,
    imageSource: item.imageSource,
    modification: null,
    cropData: item.cropData.cropped
      ? {
          pmvcName: 'postermywall.core.model.vo.CropVO',
          x: item.cropData.x,
          y: item.cropData.y,
          width: item.cropData.width,
          height: item.cropData.height,
          imageWidth: item.cropData.imageWidth,
          imageHeight: item.cropData.imageHeight,
        }
      : null,
    isBackgroundRemoved: item.removeBackground.isBackgroundRemoved,
  };
};

const getBackendImageSlideItemObjectForItem = (item: ImageSlideItemObject): ImageSlideItemBackendObject => {
  return {
    ...getBackendImageItemObjectForItem(item),
    gitype: ITEM_TYPE.IMAGESLIDE,
    slideDuration: item.slideDuration,
    horizontalAlign: item.mediaSlide.horizontalAlign,
    verticalAlign: item.mediaSlide.verticalAlign,
  };
};

const getBackendQRItemObjectForItem = (item: QRCodeItemObject): QRCodeItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    gitype: ITEM_TYPE.QR_CODE,
    isBackgroundTransparent: item.isBackgroundTransparent,
    message: item.message,
    qrBackgroundColor: item.qrBackgroundColor,
    qrForegroundColor: item.qrForegroundColor,
  };
};

const getBackendRectangleItemObjectForItem = (item: RectangleItemObject): RectangleItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    gitype: ITEM_TYPE.RECTANGLE,
    fill: item.fill,
    rx: item.rx,
    ry: item.ry,
  };
};

const getBackendVectorItemObjectForItem = (item: VectorItemObject): VectorItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    gitype: ITEM_TYPE.VECTOR,
    fillAlpha: item.fill.fillAlpha,
    fillColor: item.fill.fillColor[0],
    fileName: item.fileName,
    isComplexSVG: !item.isCustomisable,
    lockAspectRatio: false,
    source: item.source,
    strokeColor: item.border.solidBorderColor,
    strokeWeight: item.border.solidBorderThickness,
  };
};

const getBackendVideoItemObjectForItem = (item: VideoItemObject): VideoItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    ...getBackendEffectObjectFromItem(item),
    removeBackground: getBackendRemovedVideoBackgroundObjectFromItem(item),
    gitype: ITEM_TYPE.VIDEO,
    duration: item.duration,
    endTime: item.endTime,
    fileExtension: item.fileExtension,
    frameRate: item.frameRate,
    hasTransparency: item.hasTransparency,
    hashedFilename: item.hashedFilename,
    isMuted: item.isMuted,
    startTime: item.startTime,
    videoSource: item.videoSource,
  };
};

const getBackendVideoSlideItemObjectForItem = (item: VideoSlideItemObject): VideoSlideItemBackendObject => {
  return {
    ...getBackendVideoItemObjectForItem(item),
    gitype: ITEM_TYPE.VIDEOSLIDE,
    slideDuration: item.slideDuration,
    horizontalAlign: item.mediaSlide.horizontalAlign,
    verticalAlign: item.mediaSlide.verticalAlign,
  };
};

const getBackendStickerItemObjectForItem = (item: StickerItemObject): StickerItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    ...getBackendEffectObjectFromItem(item),
    gitype: ITEM_TYPE.STICKER,
    duration: item.duration,
    endTime: item.duration,
    frameRate: item.frameRate,
    hasTransparency: true,
    hashedFilename: item.hashedFilename,
    isMuted: true,
    startTime: 0,
    highResAnimatedSprite: item.highResAnimatedSprite,
    screenAnimatedSprite: item.screenAnimatedSprite,
  };
};

const getBackendFancyTextItemObjectForItem = (item: FancyTextItemObject): FancyTextItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    gitype: ITEM_TYPE.FANCY_TEXT,
    color1: item.colors[0] !== undefined ? item.colors[0] : -1,
    color2: item.colors[1] !== undefined ? item.colors[1] : -1,
    color3: item.colors[2] !== undefined ? item.colors[2] : -1,
    fontFamily: item.fontFamily,
    idFancyText: item.idFancyText,
    morphType: item.morphType,
    morphAmount: item.morphAmount,
    text: item.text,
  };
};

const getBackendTranscriptItemObjectForItem = (item: TranscriptItemObject): TranscriptItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    gitype: ITEM_TYPE.TRANSCRIPT,
    subtitlesHashmap: item.subtitlesHashmap,
    verticalAlign: item.verticalAlign,
    generatedFrom: item.generatedFrom,
  };
};

const getBackendTextItemObjectForItem = (item: TextItemObject): TextItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    ...getBackendTextStylesObjectFromItem(item),
    gitype: ITEM_TYPE.TEXT,
    background: item.background,
    baseWidth: item.baseWidth,
    backgroundType: item.backgroundType,
    backgroundColor: item.backgroundColor,
    backgroundColorAlpha: item.backgroundColorAlpha,
    verticalAlign: item.verticalAlign,
    verticalPadding: item.verticalPadding,
    list: getBackendTextListObjectFromItem(item),
    text: item.text,
    wrappedLines: item.wrappedLines,
  };
};

const getBackendTextSlideItemObjectForItem = (item: TextSlideItemObject): TextSlideItemBackendObject => {
  return {
    ...getBackendTextItemObjectForItem(item),
    gitype: ITEM_TYPE.TEXTSLIDE,
    slideDuration: item.slideDuration,
  };
};

const getBackendTableItemObjectForItem = (item: TableItemObject): TableItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    ...getBackendTextStylesObjectFromItem(item),
    gitype: ITEM_TYPE.TABLE,
    rows: item.rows,
    columns: item.columns,
    layoutDataMap: item.layoutDataMap,
    unusedData: item.unusedData,
    layoutStyle: item.layoutStyle,
    alternateBackgroundColor1: item.alternateBackgroundColor1,
    alternateBackgroundColor2: item.alternateBackgroundColor2,
    alternateColor1Alpha: item.alternateColor1Alpha,
    alternateColor2Alpha: item.alternateColor2Alpha,
    highlightedBackgroundColor: item.highlightedBackgroundColor,
    highlightedBackgroundColorAlpha: item.highlightedBackgroundColorAlpha,
    highlightedTextColor: item.highlightedTextColor,
    xSpacing: item.xSpacing,
    ySpacing: item.ySpacing,
    fontFamily2: item.fontFamily2,
    isBold2: item.isBold2,
    isItalic2: item.isItalic2,
    underLine2: item.underLine2,
    lineThrough2: item.lineThrough2,
    backgroundType: item.backgroundType,
    backgroundColor: item.backgroundColor,
    backgroundColorAlpha: item.backgroundColorAlpha,
  };
};

const getBackendMenuItemObjectForItem = (item: MenuItemObject): MenuItemBackendObject => {
  return {
    ...getBackendTableItemObjectForItem(item),
    gitype: ITEM_TYPE.MENU,
    itemIds: item.itemIds,
    copiedItemIds: item.copiedItemIds,
    iconsColor: item.iconsColor,
    iconsSize: item.iconsSize,
    wrappingInfo: item.wrappingInfo,
  };
};

const getBackendTabsItemObjectForItem = (item: TabsItemObject): TabsItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    ...getBackendTextStylesObjectFromItem(item),
    gitype: ITEM_TYPE.TAB,
    text: item.text,
    numTabs: item.numTabs,
    separatorColor: item.separatorColor,
    separatorType: item.separatorType,
    backgroundType: item.backgroundType,
    backgroundColor: item.backgroundColor,
    backgroundColorAlpha: item.backgroundColorAlpha,
  };
};

const getBackendSlideshowItemObjectForItem = (item: SlideshowItemObject): SlideshowItemBackendObject => {
  return {
    ...getBaseBackendItemObjectFromItem(item),
    gitype: ITEM_TYPE.SLIDESHOW,
    introAnimationPadding: item.introAnimationPadding,
    introDelay: item.introDelay,
    hasIntroOutroTransition: item.hasIntroOutroTransition,
    transition: getBackendTransitionObjectFromItem(item),
    slideshowItems: getBackendSlideItemsObjectForSlideshow(item),
  };
};

const getBackendSlideItemsObjectForSlideshow = (slideshowItem: SlideshowItemObject): SlideBackendObject[] => {
  const backendSlidesArray = [];

  for (const hashedItemID of slideshowItem.slides.slidesOrder) {
    backendSlidesArray.push(getBackendItemObjectForItem(slideshowItem.slides.slidesHashMap[hashedItemID]) as SlideBackendObject);
  }

  return backendSlidesArray;
};

const getBackendEffectObjectFromItem = (item: ItemObject): EffectsBackend => {
  if (!('effects' in item)) {
    throw new Error(`Item doesnt have effect`);
  }

  return {
    glowType: getGlowTypeFromItem(item),
    glowColor: item.aura.glowColor,
    brightness: item.effects.brightness,
    blackAndWhite: item.effects.blackAndWhite,
    blur: item.effects.blur,
    contrast: item.effects.contrast,
    edgeType: item.effects.edgeType,
    edgeThickness: item.effects.edgeThickness,
    gamma: {
      isEnabled: item.effects.gamma.isEnabled,
      red: item.effects.gamma.red,
      green: item.effects.gamma.green,
      blue: item.effects.gamma.blue,
    },
    invert: item.effects.invert,
    multiply: item.effects.multiply,
    multiplyColor: item.effects.multiplyColor,
    multiplyOpacity: item.effects.multiplyOpacity,
    removeColor: item.effects.removeColor,
    removeColorValue: item.effects.removeColorValue,
    removeColorThickness: item.effects.removeColorThickness,
    pixelate: item.effects.pixelate,
    saturation: item.effects.saturation,
    sepia: item.effects.sepia,
    tint: item.effects.tint,
    tintColor: item.effects.tintColor,
    tintOpacity: item.effects.tintOpacity,
    vibrance: item.effects.vibrance,
    maskingItems: getBackendMaskingObjectFromItem(item),
  };
};

const getGlowTypeFromItem = (item: ItemObject): GlowType => {
  switch (item.aura.type) {
    case AuraType.LIGHT_GLOW:
      return GlowType.LIGHT;
    case AuraType.STRONG_GLOW:
      return GlowType.STRONG;
    case AuraType.NONE:
    case AuraType.LIGHT_SHADOW:
    case AuraType.STRONG_SHADOW:
    case AuraType.CUSTOM_SHADOW:
      return GlowType.NONE;
    default:
      throw new Error(`Unhandled aura type for getGlowTypeFromItem: ${item.aura.type}`);
  }
};

const getShadowTypeFromItem = (item: ItemObject): ShadowType => {
  switch (item.aura.type) {
    case AuraType.LIGHT_SHADOW:
      return ShadowType.LIGHT;
    case AuraType.STRONG_SHADOW:
      return ShadowType.STRONG;
    case AuraType.CUSTOM_SHADOW:
      return ShadowType.CUSTOM;
    case AuraType.NONE:
    case AuraType.LIGHT_GLOW:
    case AuraType.STRONG_GLOW:
      return ShadowType.NONE;
    default:
      throw new Error(`Unhandled aura type for getShadowTypeFromItem: ${item.aura.type}`);
  }
};

const getBackendMaskingObjectFromItem = (item: ItemObject): MaskingItemsBackend => {
  if (!('masking' in item)) {
    return [];
  }

  return item.masking.maskingItem ? [getBackendMaskingItemFromMaskingItem(item.masking.maskingItem)] : [];
};

const getBackendMaskingItemFromMaskingItem = (maskingItem: MaskingItemObject): MaskingItemBackend => {
  if (maskingItem.type === MaskingType.SHAPE) {
    return getBackendShapeMaskingObjectFromMaskingItem(maskingItem as MaskingShape);
  }

  if (maskingItem.type === MaskingType.TEXT) {
    return getBackendTextMaskingObjectItemFromMaskingItem(maskingItem as MaskingText);
  }

  if (maskingItem.type === MaskingType.FREEHAND) {
    return getBackendFreehandMaskingObjectFromMaskingItem(maskingItem as MaskingFreehand);
  }

  throw new Error(`Unknown masking type`);
};

const getBackendTextMaskingObjectItemFromMaskingItem = (maskingItem: MaskingText): MaskingTextBackend => {
  return {
    type: MaskingType.TEXT,
    pmvcName: 'postermywall.core.model.vo.MaskingTextVO',
    maskEffect: maskingItem.maskEffect,
    imageWidth: maskingItem.imageWidth,
    imageHeight: maskingItem.imageHeight,
    angle: maskingItem.angle,
    height: maskingItem.height,
    width: maskingItem.width,
    left: maskingItem.left,
    top: maskingItem.top,
    scaleX: maskingItem.scaleX,
    scaleY: maskingItem.scaleY,
    text: maskingItem.text,
    fontSize: maskingItem.textStyles.fontSize,
    fontFamily: maskingItem.textStyles.fontFamily,
    charSpacing: maskingItem.textStyles.letterSpacing,
    lineHeight: maskingItem.textStyles.leading,
    textAlign: maskingItem.textStyles.textAlign,
    isBold: maskingItem.textStyles.isBold,
    isItalic: maskingItem.textStyles.isItalic,
    underLine: maskingItem.textStyles.underLine,
    lineThrough: maskingItem.textStyles.lineThrough,
  };
};

const getBackendShapeMaskingObjectFromMaskingItem = (maskingItem: MaskingShape): MaskingShapeBackend => {
  return {
    type: MaskingType.SHAPE,
    shapeName: maskingItem.shapeName,
    shapeType: ShapeType.VECTOR,
    insideMasking: maskingItem.insideMasking,
    angle: maskingItem.angle,
    height: maskingItem.height,
    width: maskingItem.width,
    left: maskingItem.left,
    top: maskingItem.top,
    scaleX: maskingItem.scaleX,
    scaleY: maskingItem.scaleY,
    pmvcName: 'postermywall.core.model.vo.MaskingShapeVO',
    maskEffect: maskingItem.maskEffect,
    imageWidth: maskingItem.imageWidth,
    imageHeight: maskingItem.imageHeight,
  };
};

const getBackendFreehandMaskingObjectFromMaskingItem = (maskingItem: MaskingFreehand): MaskingFreehandBackend => {
  return {
    pmvcName: 'postermywall.core.model.vo.MaskingFreeHandVO',
    type: MaskingType.FREEHAND,
    maskPoints: maskingItem.maskPoints,
    isMaskCurved: maskingItem.isMaskCurved,
    maskEffect: maskingItem.maskEffect,
    imageWidth: maskingItem.imageWidth,
    imageHeight: maskingItem.imageHeight,
  };
};

const getBackendTextStylesObjectFromItem = (item: ItemObject): TextStylesBackend => {
  if (!('textStyles' in item)) {
    throw new Error(`Item doesn't have textStyles`);
  }

  let gradientFillColor1: RGB = [255, 255, 255];
  let gradientFillColor2: RGB = [184, 184, 184];

  if ('fill' in item.textStyles && (item.textStyles.fill.fillType === FillTypes.LINEAR_GRADIENT || item.textStyles.fill.fillType === FillTypes.RADIAL_GRADIENT)) {
    [gradientFillColor1, gradientFillColor2] = item.textStyles.fill.fillColor;
  }

  return {
    fontSize: item.textStyles.fontSize,
    fontFamily: item.textStyles.fontFamily,
    fontStyle: item.textStyles.fontStyle,
    fontWeight: item.textStyles.fontWeight,
    letterSpacing: item.textStyles.letterSpacing,
    fontLicense: item.textStyles.fontLicense,
    leading: item.textStyles.leading,
    textAlign: item.textStyles.textAlign,
    script: item.textStyles.script,
    isBold: item.textStyles.isBold,
    isItalic: item.textStyles.isItalic,
    underLine: item.textStyles.underLine,
    lineThrough: item.textStyles.lineThrough,
    stroke: item.textStyles.stroke,
    strokeColor: item.textStyles.strokeColor,
    strokeWidth: item.textStyles.strokeWidth,
    color: item.textStyles.fill.fillColor[0],
    fillType: item.textStyles.fill.fillType,
    gradientFillColor1,
    gradientFillColor2,
  };
};

const getBackendTextListObjectFromItem = (item: ItemObject): TextListBackend => {
  if (!('list' in item)) {
    throw new Error(`Item doesn't have list`);
  }

  const gradientFillColor1: RGB = [255, 255, 255];
  const gradientFillColor2: RGB = [255, 255, 255];

  return {
    color: item.list.fill.fillColor[0],
    gradientColor1: item.list.fill.fillColor[1] ?? gradientFillColor1,
    gradientColor2: item.list.fill.fillColor[2] ?? gradientFillColor2,
    type: item.list.type,
    colorType: item.list.fill.fillType,
    style: item.list.style,
    width: item.list.width,
  };
};

const getBackendTransitionObjectFromItem = (item: ItemObject): TransitionObject => {
  if (!('transition' in item)) {
    throw new Error(`Item doesn't have transition`);
  }

  return {
    type: item.transition.type,
    speed: item.transition.speed,
    color: item.transition.color,
  };
};

const getBaseBackendItemObjectFromItem = (item: ItemObject): BaseItemBackendObject => {
  let gradientFillColor1: RGB = [255, 255, 255];
  let gradientFillColor2: RGB = [255, 255, 255];

  if ('fill' in item && (item.fill.fillType === FillTypes.LINEAR_GRADIENT || item.fill.fillType === FillTypes.RADIAL_GRADIENT)) {
    [gradientFillColor1, gradientFillColor2] = item.fill.fillColor;
  }

  return {
    uid: item.uid,
    idOriginalOwner: item.idOriginalOwner ?? null,
    x: item.x,
    y: item.y,
    alpha: item.alpha,
    width: item.width,
    height: item.height,
    rotation: item.rotation,
    visible: item.visible,
    clickableLink: item.clickableLink,
    scaleX: item.scaleX,
    scaleY: item.scaleY,
    flipX: item.flipX,
    flipY: item.flipY,
    lockMovement: item.lockMovement,
    version: item.version,
    dropShadow: getShadowTypeFromItem(item),
    dropShadowColor: item.aura.dropShadowColor,
    dropShadowAlpha: item.aura.dropShadowAlpha,
    dropShadowAngle: item.aura.dropShadowAngle,
    dropShadowDistance: item.aura.dropShadowDistance,
    dropShadowBlur: item.aura.dropShadowBlur,
    solidBorderType: item.border.solidBorderType,
    solidBorderColor: item.border.solidBorderColor,
    solidBorderThickness: item.border.solidBorderThickness,
    glow: 0,
    fillType: 'fill' in item ? item.fill.fillType : FillTypes.SOLID,
    gradientFillColor1,
    gradientFillColor2,
    patternFill: 0,
  };
};

const getBackendRemovedVideoBackgroundObjectFromItem = (item: VideoItemObject | VideoSlideItemObject): RemoveVideoBackgroundBackend => {
  return {
    isBackgroundRemoved: item.removeBackground.isBackgroundRemoved,
    startTime: item.removeBackground.trimData.startTime ?? 0,
    endTime: item.removeBackground.trimData.endTime ?? 0,
  };
};
